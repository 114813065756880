import React, {useEffect, useState} from "react";
import {Button, Form, FloatingLabel, Col, Row, Image, ListGroup, Spinner, Alert} from "react-bootstrap";
import {BsImage, BsPlay, BsTrash} from "react-icons/bs";
import ImageUploading from 'react-images-uploading';
import QrScanner from 'qr-scanner';

export default function CAP50Form (props) {
    const [serialValidation, setSerialValidation] = useState({status: '', message: ""});
    const [isSubmitting, setIsSubmitting] = useState(false); // For processing
    const [form, setForm] = useState({photos: [],
        monteur: '',
        serial: '071',
        comment: 'Laadpunt functioneel getest en in orde bevonden.',
        meerwerk: false,
        damaged: false,
        nietGevonden: false
    });

    const setField = (field = '', value) => {
        setForm({
            ...form,
            [field]: value
        })
    }
    const setFields = (updatedFields = {}) => {
        setForm({
            ...form,
            ...updatedFields
        })
    }

    const startProcessing = () => {
        // Submit form
        setIsSubmitting(true);
        let formData = new FormData();
        formData.append('monteur', form.monteur);
        formData.append('serial', form.serial);
        formData.append('comment', form.comment);
        formData.append('meerwerk', form.meerwerk);
        formData.append('damaged', form.damaged);
        formData.append('nietGevonden', form.nietGevonden);

        form.photos.forEach((photo=>formData.append('photo', photo.file)))
        console.log("Form Data", formData);

        fetch("https://maps.modutec.nl/forms/cap50", {
            body: formData,
            method: "post",
        }).then(response=>{
            setIsSubmitting(false);
            console.log("Response", response);
            if (response.ok) {
                alert("Het formulier is verzonden! 👍");
                window.location.reload(false); // Because the QR-reader is prone to memoryleaks
            } else {
                response.text().then(text => alert("Er is iets misgegaan bij het verzenden van het formulier. Probeer het nog eens. Foutmelding: " + text));
            }
        }).catch(err=>{
            setIsSubmitting(false);
            console.log("Fetch error bij verzenden formulier", err);
            alert("Er is iets misgegaan bij het verzenden van het formulier. Probeer het nog eens. Foutmelding: " + err.toString());
        });
    };
    const onChangePhotos = (imageList, addUpdateIndex) => {
        if (addUpdateIndex){ // Not present if an image was removed
            addUpdateIndex.forEach(updateIndex=>{
                QrScanner.scanImage(imageList[updateIndex].file, { returnDetailedScanResult: true })
                    .then(checkForSerial)
                    .catch(error => console.log(error || 'No QR code found.'));
            })
        }
        setField('photos', imageList);
    };

    // Check to see if a decoded QR-code contains a serialnumber
    const checkForSerial = (result) => {
        console.log("Checking scanned code", result);
        if (form.serial !== '' && form.serial !== '071') {// Don't overwrite user input
            return;
        }
        let newSerial = result.data;
        if (newSerial.includes('http')) {
            let splittedURL = newSerial.split("/");
            if (splittedURL.length === 4) { // http: /  / tnm.io / ########
                newSerial = splittedURL[3];
            }
        }
        if (newSerial.length === 7) { // Sticker has a printing mistake, add 0
            newSerial = "0" + newSerial;
        }
        setField('serial', newSerial);
        checkSerial();
    }

    const checkSerial = () => {
        if (form.serial.length !== 8) {return};

        fetch("https://maps.modutec.nl/forms/cap50/serial/" + form.serial).then(response=>{
            console.log("Serial checked", response);
            response.json().then((result)=>(setSerialValidation(result)))
        }).catch(err=>{
            console.log("Fetch error bij serienummercheck", err);
        });
    }

    const processNietGevonden = (newValue) => {
        let updatedFields = {nietGevonden: newValue};
        if (newValue === true) {
            updatedFields.meerwerk = false;
            updatedFields.damaged = false;
            if (form.comment === 'Laadpunt functioneel getest en in orde bevonden.') {
                updatedFields.comment = "Laadpunt niet gevonden op het opgegeven adres.";
            }
        } else if (newValue === false) {
            if (form.comment === '' || form.comment === "Laadpunt niet gevonden op het opgegeven adres.") {
                updatedFields.comment = 'Laadpunt functioneel getest en in orde bevonden.';
            }
        }
        setFields(updatedFields);
    }

    useEffect(() => { // Repeats as tasks changes
        const queryParams = new URLSearchParams(window.location.search);
        const defaultMonteur = queryParams.get('monteur');
        if (defaultMonteur !== null){setField('monteur', defaultMonteur)};
    }, []);

    return (<>
        <Form className="col-md-8 offset-md-2 col-lg-8 offset-lg-2">
            <div className="my-5">
                <h1>CAP50 Formulier</h1>
                <p>Gebruik dit formulier om door te geven dat je een CAP50-actie hebt afgerond.</p>
                <p>Wil je een formulier aanpassen dat je eerder al hebt verzonden? Vul dit formulier dan nogmaals in met hetzelfde serienummer en stuur even een bericht naar de planning.</p>
                <Form.Group>
                    <FloatingLabel controlId="monteur" label="Initialen monteur">
                        <Form.Control type="text" disabled={isSubmitting} required value={form.monteur} onChange={(e)=>setField('monteur', e.target.value)} />
                    </FloatingLabel>
                </Form.Group>
            </div>
            <div className="m-0 separator-padding">
                <hr className="separator" />
            </div>
            <div className="my-5">
                <h2>Foto's</h2>
                <p>Voeg minimaal <strong>twee foto's</strong> toe: een foto van het <strong>serienummer van de lader</strong> en van de <strong>nieuwe socket</strong>.</p>
                <br />
                <ImageUploading
                    multiple
                    value={form.photos}
                    onChange={onChangePhotos}
                    maxNumber={5}
                    dataURLKey="data_url"
                >
                    {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                      }) => (
                        <ListGroup className="mb-3">

                            {imageList.map((image, index) => (
                                <ListGroup.Item key={index}>
                                    <Row>
                                        <Col xs="auto">
                                            <Image src={image['data_url']} alt="Foto" fluid thumbnail style={{maxHeight: '160px'}} />
                                        </Col>
                                        <Col>
                                            Foto {(index+1)}
                                        </Col>
                                        <Col xs="auto">
                                            <Button onClick={() => onImageRemove(index)} variant="danger">
                                                <BsTrash aria-label="Verwijderen" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                            <ListGroup.Item>
                                <Row>
                                    <Col className='text-center py-3'>
                                        <Button
                                            style={isDragging ? { color: 'red' } : undefined}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                        >
                                            <BsImage /> Toevoegen
                                        </Button>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                    )}
                </ImageUploading>
            </div>
            <div className="my-5 separator-padding">
                <hr className="separator" />
            </div>
            <div className="my-5">
                <Form.Group>
                    <FloatingLabel controlId="serial" label="Serienummer laadpunt">
                        <Form.Control type="number" disabled={isSubmitting} required value={form.serial} onChange={(e)=>setField('serial', e.target.value)} onBlur={checkSerial} />
                    </FloatingLabel>
                    {serialValidation.message.length>0 && <Alert variant="primary">
                        {serialValidation.message}
                    </Alert>}
                    <FloatingLabel controlId="comment" label="Opmerkingen">
                        <Form.Control as='textarea'  disabled={isSubmitting} style={{height: '114px'}} onChange={(e)=>setField('comment', e.target.value)} value={form.comment} />
                    </FloatingLabel>
                </Form.Group>
            </div>
            <div className="my-3">
                <Form.Group>
                    <Form.Switch label="Laadpunt niet gevonden" id="nietGevonden" onChange={e => processNietGevonden(!form.nietGevonden)} checked={form.nietGevonden} />
                </Form.Group>
            </div>
            {form.nietGevonden ? <></> :
                <><div className="my-3">
                    <Form.Group>
                        <Form.Switch label="Terug voor meerwerk?" id="meerwerk"
                                     onChange={e => setField('meerwerk', !form.meerwerk)} checked={form.meerwerk}/>
                    </Form.Group>
                </div>
                    <div className="my-3">
                        <Form.Group>
                            <Form.Switch label="Op de socket zijn sporen van oververhitting aangetroffen" id="damaged"
                                         onChange={e => setField('damaged', !form.damaged)} checked={form.damaged}/>
                        </Form.Group>
                    </div></>
            }
            <div className="mv-5" style={{textAlign: 'right'}}>
                <Button variant="primary" className="my-5" disabled={isSubmitting || form.photos.length<2 || form.serial.length<8 || form.monteur.length < 2} onClick={startProcessing}>
                    {isSubmitting ?
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <><BsPlay size={28} />{' '}Versturen!</>
                    }
                </Button>
            </div>
        </Form>
    </>);
}

