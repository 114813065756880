import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import CAP50Form from "./routes/CAP50Form";
import TE2205Form from "./routes/TE2205Form";
import {NavigateWithQuery} from "./components/NavigateWithQuery";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />}>
                    <Route path="cap50" element={<CAP50Form />} />
                    <Route path="te2205" element={<TE2205Form />} />
                    <Route
                        path="*"
                        element={
                            <main style={{ padding: "1rem" }}>
                                <strong>Page not found!</strong>
                            </main>
                        }
                    />

                    <Route
                        index
                        element={<NavigateWithQuery to={`/cap50`} replace />}
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
