import React, {useEffect, useState} from "react";
import {Button, Form, FloatingLabel, Col, Row, Image, ListGroup, Spinner, Alert} from "react-bootstrap";
import {BsImage, BsPlay, BsPlus, BsTrash} from "react-icons/bs";
import ImageUploading from 'react-images-uploading';

export default function TE2205Form (props) {
    const [serialValidation, setSerialValidation] = useState({status: '', message: ""});
    const [isSubmitting, setIsSubmitting] = useState(false); // For processing
    const [form, setForm] = useState({photos: [],
        monteur: '',
        serial: 'TNLP00',
        comment: 'APN-update, firmware-upgrade uitgevoerd.',
        meerwerk: false,
        nietGevonden: false,
        damage: false,
        rust: false,
        graffiti: false,
        toolVersion: null
    });

    const setField = (field = '', value) => {
        setForm({
            ...form,
            [field]: value
        })
    }
    const setFields = (updatedFields = {}) => {
        setForm({
            ...form,
            ...updatedFields
        })
    }

    const startProcessing = () => {
        // Submit form
        setIsSubmitting(true);
        let formData = new FormData();
        formData.append('monteur', form.monteur);
        formData.append('serial', form.serial);
        formData.append('comment', form.comment);
        formData.append('meerwerk', form.meerwerk);
        formData.append('nietGevonden', form.nietGevonden);

        formData.append('damage', form.damage);
        formData.append('rust', form.rust);
        formData.append('graffiti', form.graffiti);
        if (form.toolVersion){
            formData.append('toolVersion', form.toolVersion);
        }
        if (form.status){
            formData.append('status', form.status);
        }
        form.photos.forEach((photo=>formData.append('photo', photo.file)))
        console.log("Form Data", formData);

        fetch("https://maps.modutec.nl/forms/te2205", {
            body: formData,
            method: "post",
        }).then(response=>{
            setIsSubmitting(false);
            console.log("Response", response);
            if (response.ok) {
                alert("The form was sent successfully! / Het formulier is verzonden! 👍");
                window.location.reload(false); // Because the QR-reader is prone to memoryleaks
            } else {
                response.text().then(text => alert("Something went wrong while sending the form, please try again. / Er is iets misgegaan bij het verzenden van het formulier. Probeer het nog eens. Foutmelding: " + text));
            }
        }).catch(err=>{
            setIsSubmitting(false);
            console.log("Fetch error bij verzenden formulier", err);
            alert("Something went wrong while sending the form, please try again. / Er is iets misgegaan bij het verzenden van het formulier. Probeer het nog eens. Foutmelding: " + err.toString());
        });
    };
    const onChangePhotos = (imageList, addUpdateIndex) => {
        setField('photos', imageList);
    };

    const checkSerial = () => {
        if (form.serial.length < 6) {return};

        fetch("https://maps.modutec.nl/forms/te2205/serial/" + form.serial).then(response=>{
            console.log("Serial checked", response);
            response.json().then((result)=>(setSerialValidation(result)))
        }).catch(err=>{
            console.log("Fetch error bij serienummercheck", err);
        });
    }

    const processNietGevonden = (newValue) => {
        let updatedFields = {nietGevonden: newValue};
        if (newValue === true) {
            updatedFields.meerwerk = false;
            updatedFields.damage = false;

            updatedFields.rust = false;
            updatedFields.graffiti = false;
            if (form.comment === '' || form.comment === 'APN-update, firmware-upgrade uitgevoerd.') {
                updatedFields.comment = "Laadpunt niet gevonden op het opgegeven adres.";
            }
        } else if (newValue === false) {
            if (form.comment === "Laadpunt niet gevonden op het opgegeven adres.") {
                updatedFields.comment = 'APN-update, firmware-upgrade uitgevoerd.';
            }
        }
        setFields(updatedFields);
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        let newValues = {};
        const defaultMonteur = queryParams.get('monteur');
        if (defaultMonteur !== null){newValues.monteur = defaultMonteur};
        const defaultSerial = queryParams.get('serial');
        if (defaultSerial !== null){newValues.serial = defaultSerial};

        const toolVersion = queryParams.get('v');
        if (toolVersion !== null){newValues.toolVersion = toolVersion};

        const status = queryParams.get('status');
        if (status !== null){newValues.status = status};
        setFields(newValues);
    }, []);

    return (<>
        <Form className="col-md-8 offset-md-2 col-lg-8 offset-lg-2">
            <div className="my-5">
                <h1>TE2205 Form</h1>
                <p>You can use this form to let us know that you have finished a TE2205 service visit.<br />
                If you want to change a form that you submitted earlier, please fill out this form again using the same serial number, and send a message to the planners.</p>
                <p>Gebruik dit formulier om door te geven dat je een TE2205-actie hebt afgerond.<br />
                Wil je een formulier aanpassen dat je eerder al hebt verzonden? Vul dit formulier dan nogmaals in met hetzelfde serienummer en stuur even een bericht naar de planning.</p>
                <Form.Group>
                    <FloatingLabel controlId="monteur" label="Engineer's initials / Initialen monteur">
                        <Form.Control type="text" disabled={isSubmitting} required value={form.monteur} onChange={(e)=>setField('monteur', e.target.value)} />
                    </FloatingLabel>
                </Form.Group>
            </div>
            <div className="m-0 separator-padding">
                <hr className="separator" />
            </div>
            <div className="my-5">
                <h2>Photos / Foto's</h2>
                <p>Attach at least <strong>three photos</strong>: one showing the <strong>charge point's serial</strong>, one showing the <strong>new sim card</strong> and one showing the <strong>charge point</strong>.</p>

                <p>Voeg minimaal <strong>drie foto's</strong> toe: een foto van het <strong>serienummer van de lader</strong>, van de <strong>nieuwe simkaart</strong> en van <strong>laadpaal</strong>.</p>
                <br />
                <ImageUploading
                    multiple
                    value={form.photos}
                    onChange={onChangePhotos}
                    maxNumber={5}
                    dataURLKey="data_url"
                >
                    {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                      }) => (
                        <ListGroup className="mb-3">

                            {imageList.map((image, index) => (
                                <ListGroup.Item key={index}>
                                    <Row>
                                        <Col xs="auto">
                                            <Image src={image['data_url']} alt="Foto" fluid thumbnail style={{maxHeight: '160px'}} />
                                        </Col>
                                        <Col>
                                            Foto {(index+1)}
                                        </Col>
                                        <Col xs="auto">
                                            <Button onClick={() => onImageRemove(index)} variant="danger">
                                                <BsTrash aria-label="Verwijderen" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                            <ListGroup.Item>
                                <Row>
                                    <Col className='text-center py-3'>
                                        <Button
                                            style={isDragging ? { color: 'red' } : undefined}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                        >
                                            <BsImage /> <BsPlus />
                                        </Button>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                    )}
                </ImageUploading>
            </div>
            <div className="my-5 separator-padding">
                <hr className="separator" />
            </div>
            <div className="my-5">
                <Form.Group>
                    <FloatingLabel controlId="serial" label="Charge point serial / Serienummer LP">
                        <Form.Control type="text" disabled={isSubmitting} required value={form.serial} onChange={(e)=>setField('serial', e.target.value)} onBlur={checkSerial} />
                    </FloatingLabel>
                    {serialValidation.message.length>0 && <Alert variant="primary">
                        {serialValidation.message}
                    </Alert>}
                    <FloatingLabel controlId="comment" label="Comments / Opmerkingen">
                        <Form.Control as='textarea'  disabled={isSubmitting} style={{height: '114px'}} onChange={(e)=>setField('comment', e.target.value)} value={form.comment} />
                    </FloatingLabel>
                </Form.Group>
            </div>
            <div className="my-3">
                <Form.Group>
                    <Form.Switch label="Charge point not found / Laadpunt niet gevonden" id="nietGevonden" onChange={e => processNietGevonden(!form.nietGevonden)} checked={form.nietGevonden} />
                </Form.Group>
            </div>
            {form.nietGevonden ? <></> :<>
                    <div className="my-3">
                        <Form.Group>
                            <Form.Switch label="Extra visit needed? / Terug voor meerwerk?" id="meerwerk"
                                         onChange={e => setField('meerwerk', !form.meerwerk)} checked={form.meerwerk}/>
                        </Form.Group>
                    </div>

                    <div className="my-3">
                        <Form.Group>
                            <Form.Switch label="Damage on charge point / Laadpaal heeft schade" id="damage"
                                         onChange={e => setField('damage', !form.damage)} checked={form.damage}/>
                        </Form.Group>
                    </div>
                    <div className="my-3">
                        <Form.Group>
                            <Form.Switch label="Rust on charge point / Laadpaal heeft roest" id="rust"
                                         onChange={e => setField('rust', !form.rust)} checked={form.rust}/>
                        </Form.Group>
                    </div>
                    <div className="my-3">
                        <Form.Group>
                            <Form.Switch label="Graffiti on charge point / Laadpaal heeft graffiti" id="graffiti"
                                         onChange={e => setField('graffiti', !form.graffiti)} checked={form.graffiti}/>
                        </Form.Group>
                    </div>
                </>
            }
            <div className="mv-5" style={{textAlign: 'right'}}>
                <Button variant="primary" className="my-5" disabled={isSubmitting || form.photos.length<3 || form.serial.length<6 || form.monteur.length < 2} onClick={startProcessing}>
                    {isSubmitting ?
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <><BsPlay size={28} />{' '}Let's go!</>
                    }
                </Button>
            </div>
        </Form>
    </>);
}

