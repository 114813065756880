import logo from './logo.svg';
import './App.css';
import React from "react";
import {Outlet} from "react-router-dom";
import {Container} from "react-bootstrap";

export default function App (props) {

    return (<>
        <header>
            <img src={logo} className="App-logo" alt="Modutec logo"/>
        </header>
        <Container>
            <Outlet />
        </Container>
    </>);
}

